body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.Polaris-Frame__Main:has(.reactgrid) {
  padding: 80px 10px !important;
}

.display-only-for-print {
  display: none !important;
}

.display-only-for-print body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}
.display-only-for-print .container {
  padding: 20px;
  width: 100%;
}
.display-only-for-print .header {
  text-align: center;
  margin-bottom: 20px;
}
.display-only-for-print .section {
  margin-bottom: 30px;
}
.display-only-for-print .section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.display-only-for-print .details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.display-only-for-print .details div {
  width: 30%;
}
.display-only-for-print .table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}
.display-only-for-print .table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.table th {
  background-color: #f2f2f2;
}

@media (min-width: 600px) {
  .pricing-table-alignment .rdt_TableCol:nth-child(n + 2) {
    flex-direction: row-reverse;
  }

  .pricing-table-alignment .rdt_TableCell:nth-child(n + 2) {
    flex-direction: row-reverse;
    margin-right: 15px;
    padding-right: 5px;
  }

  .expandable-pricing-table-alignment .rdt_TableCol:nth-child(n + 3) {
    flex-direction: row-reverse;
  }

  .expandable-pricing-table-alignment .rdt_TableCell:nth-child(n + 3) {
    flex-direction: row-reverse;
    margin-right: 15px;
  }
}

@media print {
  .no-print {
    display: none !important;
  }

  .display-only-for-print {
    display: block !important;
  }

  .chiller-log-wrapper {
    margin: 50px !important;
  }
}

.no-circuit-table .Spreadsheet__header,
.no-circuit-table .Spreadsheet__cell {
  min-height: 1.4em;
  max-height: 1.4em;
  height: 1.4em;
  width: 6em;
  min-width: 6em;
}

.circuit-table .Spreadsheet__header,
.circuit-table .Spreadsheet__cell {
  min-height: 1.4em;
  max-height: 1.4em;
  height: 1.4em;
  width: 6em;
  min-width: 6em;
}

.compressor-table .Spreadsheet__header,
.compressor-table .Spreadsheet__cell {
  min-height: 1.4em;
  max-height: 1.4em;
  height: 1.4em;
  width: 6em;
  min-width: 6em;
}

.no-circuit-table .Spreadsheet__header,
.circuit-table .Spreadsheet__header,
.compressor-table .Spreadsheet__header {
  color: #000;
}

.no-circuit-table tr td:nth-child(3n + 1) {
  color: #000 !important;
  opacity: 1 !important;
  border-right: 2px solid black !important;
}

.no-circuit-table tr th:nth-child(3n + 1) {
  color: #000 !important;
  opacity: 1 !important;
  border-right: 2px solid black !important;
}

.circuit-table.values-data tr td:nth-child(1),
.compressor-table.values-data tr td:nth-child(1) {
  color: #000 !important;
  opacity: 1 !important;
  border-right: 2px solid black !important;
  background: #fff;
}

.circuit-table.values-data tr th:nth-child(1),
.compressor-table.values-data tr th:nth-child(1) {
  color: #000 !important;
  opacity: 1 !important;
  border-right: 2px solid black !important;
  background: #f5f5f5;
}

.historical-data {
  display: flex !important;
  float: left;
}

.values-data {
  display: flex !important;
}

.historical-data .Spreadsheet__active-cell,
.values-data .Spreadsheet__active-cell .historical-data td,
.values-data td,
.historical-data th,
.values-data th,
.historical-data td {
  height: 33px !important;
}

.Spreadsheet .error-cell {
  background: rgba(255, 0, 0, 0.5) !important;
}

.Spreadsheet.fixed-row-labels colgroup col:first-child {
  min-width: 200px;
}

/* .hide-labels .Spreadsheet__header {
  display: none;
}

.hide-labels .Spreadsheet__row-header {
  display: none;
} */
